






























































































































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'HomePage',
    components: {
    }
})
export default class HomePage extends Vue {

    copyLink = 'https://www.gogohelpers.com/en/login'

    recruitmentList: any = [
        {
            label: 'Market Job',
            img: require('@/assets/images/recruitment01@3x.png')
        },
        {
            label: 'Souring',
            img: require('@/assets/images/recruitment02@3x.png')
        },
        {
            label: 'Screening',
            img: require('@/assets/images/recruitment03@3x.png')
        },
        {
            label: 'Employer Interviews',
            img: require('@/assets/images/recruitment04@3x.png')
        },
        {
            label: 'Confirm Job Order',
            img: require('@/assets/images/recruitment05@3x.png')
        },
        {
            label: 'Sign Contract',
            img: require('@/assets/images/recruitment06@3x.png')
        }
    ]

    deploymentList: any = [
        {
            label: 'Medical Check',
            img: require('@/assets/images/deployment1@3x.png')
        },
        {
            label: 'TESDA Application',
            img: require('@/assets/images/deployment2@3x.png')
        },
        {
            label: 'OWWA Application',
            img: require('@/assets/images/deployment3@3x.png')
        },
        {
            label: 'PDOS Application',
            img: require('@/assets/images/deployment4@3x.png')
        },
        {
            label: 'OEC Application',
            img: require('@/assets/images/deployment5@3x.png')
        },
        {
            label: 'Deploy to Oversea',
            img: require('@/assets/images/deployment6@3x.png')
        }
    ]

    ourValueList: any = [
        {
            label: 'Integrity',
            img: require('@/assets/images/Integrity@3x.png')
        },
        {
            label: 'Compassion',
            img: require('@/assets/images/Compassion@3x.png')
        },
        {
            label: 'Accountability',
            img: require('@/assets/images/Accountability@3x.png')
        },
        {
            label: 'Customer Commitment',
            img: require('@/assets/images/CustomerCommitment@3x.png')
        }
    ]

    trainingImgList: any = [
        {
            img: require('@/assets/images/training3@2x.png')
        },
        {
            img: require('@/assets/images/training4@2x.png')
        },
        {
            img: require('@/assets/images/training5@2x.png')
        },
        {
            img: require('@/assets/images/training6@2x.png')
        },
        {
            img: require('@/assets/images/training7@2x.png')
        },
        {
            img: require('@/assets/images/training8@2x.png')
        }
    ]

    bannerList: any = [
        require('@/assets/images/Banner_OurBusiness2.png'),
        require('@/assets/images/Banner_OurBusiness.png'),
    ]

    clickFacebook() {
        window.open('https://www.facebook.com/zealcare02')
    }

    mounted() {
        if (process.env.NODE_ENV != 'production') {
            this.copyLink = 'http://gogohelpers.test.jimuitech.com/en/login';
        }
    }
}
